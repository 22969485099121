import React from "react";
import Logo from "../images/weed-logo-021.png";

function HeroHome() {
  return (
    <section className="relative dark-bg">
      <div
        className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none"
        aria-hidden="true"
      >
        <svg
          width="1360"
          height="578"
          viewBox="0 0 1360 578"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              x1="50%"
              y1="0%"
              x2="50%"
              y2="100%"
              id="illustration-01"
            >
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Hero content */}
        <div className="pt-10 pb-12 md:pt-10 md:pb-20">
          <div style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <img src={Logo} alt="" />
          </div>
          {/* Section header */}
          <div className="text-center pb-12 md:pb-16">
            <h1
              className="text-5xl text-white md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4"
              data-aos="zoom-y-out"
            >
              <span className="bg-clip-text text-brown text-transparent bg-gradient-to-r from-blue-500 to-teal-400">
                Highest Memecoin of all time
              </span>{" "}
            </h1>
            <div className="max-w-3xl mx-auto">
              {/* <p
                className="text-xl text-gray-400 mb-8"
                data-aos="zoom-y-out"
                data-aos-delay="150"
              >
                Trade Solana digital assets and Crypto collectibles on the WEED
                peer-2-peer NFT Marketplace
              </p> */}
              <div
                className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"
                data-aos="zoom-y-out"
                data-aos-delay="300"
              >
                <div>
                  <a
                    className="btn text-black bg-white hover:bg-gray-300 w-full mb-4 sm:w-auto sm:mb-0"
                    href="https://mint.solanaweed.xyz"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Mint WEED NFT
                  </a>
                </div>
                <div>
                  <a
                    className="btn text-white bg-gray-700 hover:bg-gray-700 w-full sm:w-auto sm:ml-4"
                    href="https://t.me/solanaweed"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Join Telegram Community
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Hero image */}
          <div>
            <div
              className="relative flex justify-center mb-8"
              data-aos="zoom-y-out"
              data-aos-delay="450"
            >
              <div className="flex flex-col justify-center">
                <img
                  className="mx-auto"
                  src={require("../images/hero-image.png").default}
                  width="768"
                  height="432"
                  alt="Hero"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroHome;
