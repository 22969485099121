import React from "react";
import { Link } from "react-router-dom";
import Logo from "../images/weed-logo-021.png";
import Discord from "../images/discord.svg";
import Telegram from "../images/telegram.svg";
import Twitter from "../images/twitter.svg";

function Footer() {
  return (
    <footer>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Top area: Blocks */}
        <div className="grid sm:grid-cols-12 gap-8 py-8 md:py-12 border-t border-gray-200">
          {/* 1st block */}
          <div className="sm:col-span-12 lg:col-span-6">
            <div className="">
              {/* Logo */}
              <Link to="/" className="inline-block">
                <img
                  style={{
                    height: "100px",
                    marginLeft: "-23px",
                    marginBottom: "-10px",
                  }}
                  src={Logo}
                  alt=""
                />
              </Link>
            </div>
            <div className="text-sm text-gray-600">
              Highest Memecoin of all time
            </div>
          </div>
        </div>

        {/* Bottom area */}
        <div className="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-200">
          {/* Social links */}
          <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
            <li>
              <a
                href="https://t.me/solanaweed"
                className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                aria-label="Telegram"
              >
                <img className="w-6 h-6 fill-current" src={Telegram} alt="" />
              </a>
            </li>
            <li className="ml-4">
              <a
                href="https://x.com/Solanaweed"
                className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                aria-label="Twitter"
              >
                <img className="w-6 h-6" src={Twitter} alt="" />
              </a>
            </li>
            <li className="ml-4">
              <a
                href="https://discord.gg/tSNPFcmncx"
                className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                aria-label="Discord"
              >
                <img className="w-6 h-6" src={Discord} alt="" />
              </a>
            </li>
          </ul>

          {/* Copyrights note */}
          <div className="text-sm text-gray-600 mr-4">
            © 2023 The Weed Project. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
